<template>
  <v-card class="my-2" outlined>
    <v-card-title class="mb-0 pb-0">{{ name }}</v-card-title>
    <v-card-text class="blue--text text--darken-3 text-left">
      {{ firstPub.publication }} ({{ firstPub.dispDate }})
      <div
        v-if="reprints.length > 0"
        class="blue-grey--text text--darken-3 ml-5"
      >
        Reprint<span v-if="reprints.length > 1">s</span>:
        <div v-for="reprint in reprints" :key="reprint._id" class="ml-5">
          {{ reprint.publication }} ({{ reprint.dispDate }})
        </div>
      </div>
    </v-card-text>
  </v-card>
</template>

<script>
export default {
  props: ["name", "firstPub", "reprints"]
};
</script>
