<template>
  <main-container>
    <tabbed-display
      title="Bibliography"
      :show-search="true"
      :tab-list="sectionList"
      :search-criteria="search"
      @update-search="updateSearch"
      @select-tab="selectTab"
    >
      <v-data-iterator :items="sectionContents" :search="search">
        <template #default="{items}">
          <biblio-item-list
            v-for="({ title, firstPub, reprints }, index) in items"
            :key="`pub-${index}`"
            :name="title"
            :firstPub="firstPub"
            :reprints="reprints"
          ></biblio-item-list>
        </template>
      </v-data-iterator>
    </tabbed-display>
  </main-container>
</template>

<script>
import { mapGetters } from "vuex";
import BiblioItemList from "../components/Biblio/BiblioItemList.vue";
import TabbedDisplay from "../components/DataDisplays/TabbedDisplay.vue";
import MainContainer from "../components/MainContainer.vue";
export default {
  components: {
    MainContainer,
    BiblioItemList,
    TabbedDisplay
  },

  data: () => ({
    section: 0,
    search: ""
  }),

  async updated() {
    //    await this.getBiblio();
  },

  computed: {
    ...mapGetters(["biblioEntries"]),

    sectionList() {
      const biblio = this.$store.state.biblio;
      if (!biblio) return [];

      const retObj = Object.keys(biblio)
        .map(name => {
          const position = biblio[name].position;
          return { name, position };
        })
        .sort((a, b) => a.position - b.position)
        .map(({ name }) => name);

      return retObj;
    },

    sectionContents() {
      const biblio = this.$store.state.biblio;
      const section = this.sectionList[this.section];
      if (!section) return [];

      return biblio[section].entries.sort((a, b) =>
        a.sortDate < b.sortDate ? 1 : -1
      );
    }
  },

  methods: {
    updateSearch(value) {
      this.search = value;
    },

    selectTab(value) {
      this.section = value;
    }
  }
};
</script>
